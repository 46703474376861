import { title } from "assets/jss/material-kit-react.js";

const workStyle = {
  section: {
    padding: "70px 0"
  },
  title: {
    ...title,
    marginBottom: "50px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  description: {
    color: "#999",
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },
  inputSpacing: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    paddingBottom: "7px"
  },
  formMargin: {
    marginTop: "1rem",
    marginBottom: "1rem"
  },
  buttonSuccess: {
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  buttonProgress: {
    color: 'black',
    position: 'absolute',
    top: '50%',
    left: '37%',
    marginTop: -12,
  },
  toastBody: {
    fontFamily: "Atlas Grotesk Web",
    color: "#10171D", /* #10171D */
    fontSize: "2rem !important"
  }
};

export default workStyle;
