import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import ProjectsPage from "views/ProjectsPage/ProjectsPage.js";
import ExperiencePage from "views/ExperiencePage/ExperiencePage.js";

// var hist = createBrowserHistory();

ReactDOM.render(
  <HashRouter basename="/">
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/projects" component={ProjectsPage} />
      <Route path="/about" component={ProfilePage} />
      <Route path="/experience" component={ExperiencePage} />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
