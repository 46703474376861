import { container } from "assets/jss/material-kit-react.js";

const componentsStyle = {
  container,
  brand: {
    color: "#FFFFFF",
    textAlign: "left"
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  link: {
    textDecoration: "none"
  },
  textCenter: {
    textAlign: "center"
  },
  buttonNoPadding: {
      paddingLeft: "0",
      fontFamily: "Roboto",
      fontWeight: "600",
      textTransform: "none"
  },
  blueLinks: {
    color: "#0036ff"
  },
  buttonMargins: {
    margin: "1rem 0 0 0"
  },
  zoodillyPadding: {
    paddingBottom: "2rem"
  },
  pStyles: {
    fontSize: "1.1rem"
  },
  dateStyles: {
    // make a flexbox with items spaced between
    display: "flex",
    justifyContent: "space-between",
    // center text in both x and y directions
    alignItems: "center"
  },
  companyStyles: {
    // remove margin and padding from the top and add a 1rem margin to the bottom
    marginTop: "0",
    paddingTop: "0",
    marginBottom: "1rem",
    fontSize: "20px",
    fontStyle: "italic"
  },
  noBottomMarginNoPadding: {
    marginBottom: "0",
    padding: "0 0 0 0"
  },
  betterBold: {
    // change the font weight to semi bold
    fontWeight: "450"
  }
};

export default componentsStyle;