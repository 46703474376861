/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
//import { Apps, CloudDownload } from "@material-ui/icons";
import BookIcon from '@material-ui/icons/Book';
import BuildIcon from '@material-ui/icons/Build';
import FaceIcon from '@material-ui/icons/Face';
import HomeIcon from '@material-ui/icons/Home';
import ApartmentIcon from '@material-ui/icons/Apartment';

// core components
import Button from "components/CustomButtons/Button.js";
import { Link } from 'react-router-dom'

// resume pdf
import Resume from '../../assets/pdf/drew-delong-resume.pdf'

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <Link to='/' className={classes.listItem}>
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            className={classes.navLink}
          >
            <HomeIcon></HomeIcon>
            Home
          </Button>
        </ListItem>
      </Link>
      <Link to='/projects' className={classes.listItem}>
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            className={classes.navLink}
          >
            <BuildIcon></BuildIcon>
            Projects
          </Button>
        </ListItem>
      </Link>
      <Link to='/experience' className={classes.listItem}>
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            className={classes.navLink}
          >
            <ApartmentIcon></ApartmentIcon>
            Experience
          </Button>
        </ListItem>
      </Link>
      <ListItem className={classes.listItem}>
        <Button
          href={Resume}
          target="_blank"
          color="transparent"
          className={classes.navLink}
        >
          <BookIcon></BookIcon>
          Resume
        </Button>
      </ListItem>
      <Link to='/about' className={classes.listItem}>
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            className={classes.navLink}
          >
            <FaceIcon></FaceIcon>
            About
          </Button>
        </ListItem>
      </Link>
    </List>
  );
}
