import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import { Helmet } from "react-helmet";

import styles from "assets/jss/material-kit-react/views/experiencePage.js";

const useStyles = makeStyles(styles);
const TITLE = "Experience | Delong.ai 🚀";

export default function ProjectsPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Header
        color="transparent"
        brand="🚀 Delong.ai"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <h2>Work Experience</h2>
              </GridItem>
              <GridItem>
                <div className={classNames(classes.buttonMargins, classes.dateStyles)}>
                  <h3 className={classes.noBottomMargin}>Software Consulting</h3>
                  <h3>2022 - Present</h3>
                </div>
                <div>
                  <h4 className={classes.companyStyles}>Delong.ai</h4>
                </div>

                <ul className={classes.pStyles}>
                  <li>Led the development of new financial services software to enable automated asset management using NodeJS, Solidity, Python, and AWS.</li>
                  <li>Debugged critical JavaScript flaws in the IoT production code base of a Fortune 500 company.</li>
                  <li>Architected a product roadmap study for a new distributed cloud system using Python, Docker, and AWS.</li>
                  <li>Built a small business web presence from scratch using React.</li>
                  <li>Developed a JavaScript game for a design studio's marketing campaign.</li>
                </ul>
              </GridItem>
              <GridItem>
                <div className={classNames(classes.buttonMargins, classes.dateStyles)}>
                  <h3 className={classes.noBottomMargin}>Senior Intelligence Architect</h3>
                  <h3>2021 - 2022</h3>
                </div>
                <div>
                  <h4 className={classNames(classes.blueLinks, classes.companyStyles)}>
                    <a href="#" target="_blank">GPA</a>
                  </h4>
                </div>

                <ul className={classes.pStyles}>
                  <li>Implemented microservice into production cluster with new analytics that saved thousands of dollars annually.</li>
                  <li>Led front-end development of a new SaaS product that streamlined industrial IoT systems; met Sprint deliverables on time and launched an MVP within 4 months.</li>
                  <li>Engineered an IoT Analytics system that exponentially improved productivity by scaling from a single-user system to a concurrent system available across all departments.</li>
                  <li>Deployed REST API using Python to a production environment that improved system efficiency and reduced the timeline for feature deployment by 2 months.</li>
                </ul>
              </GridItem>
              <GridItem>
                <div className={classNames(classes.buttonMargins, classes.dateStyles)}>
                  <h3 className={classes.noBottomMargin}>Software Lead/Co-Founder</h3>
                  <h3>2017 - 2022</h3>
                </div>
                <div>
                  <h4 className={classNames(classes.blueLinks, classes.companyStyles)}>
                    <a href="https://nuhni.com" target="_blank">Nuhni</a>
                  </h4>
                </div>

                <ul className={classes.pStyles}>
                  <li>Developed a SaaS product using Python and AWS that automated event signup and discovery processes, saving each client 2000 annual hours.</li>
                  <li>Created a proprietary software system using AWS, Raspberry Pis, and more that allowed for 24×7 automated account management; saving 100s of hours monthly.</li>
                  <li>Designed a profitable business model and scaled it to a valuation of around $1,000,000 without external funding.</li>
                  <li>Shaped all facets of the company's software strategy by developing novel software systems, automating costly tasks into scalable tools, managing web presence, and more.</li>
                </ul>
              </GridItem>
              <GridItem>
                <div className={classNames(classes.buttonMargins, classes.dateStyles)}>
                  <h3 className={classes.noBottomMargin}>R&D Software Engineer</h3>
                  <h3>2017 - 2019</h3>
                </div>
                <div>
                  <h4 className={classNames(classes.blueLinks, classes.companyStyles)}>
                    <a href="https://en.wikipedia.org/wiki/Y-12_National_Security_Complex" target="_blank">U.S. Department of Energy</a>
                  </h4>
                </div>

                <ul className={classes.pStyles}>
                  <li>Granted DoE research funding to architect an application utilizing reinforcement learning algorithms with Python, met all deadlines, and presented progress within a year.</li>
                  <li>Developed an application with machine learning algorithms that analyzed millions of data points to preemptively predict machine failure, enabling an exponential optimization of production uptime.</li>
                  <li>Received performance award for upgrading embedded PLC control system that tripled the system's throughput.</li>
                  <li>Served as software lead for a large project by undertaking responsibilities like project planning, full-stack development, and acting liaison between the project team and management for all software needs.</li>
                  <li>Obtained a U.S DoE <a href="https://en.wikipedia.org/wiki/Q_clearance" target="_blank">“Q” Clearance</a> by demonstrating a track record of extreme reliability and trustworthiness.</li>
                </ul>
              </GridItem>
              <GridItem>
              <div className={classNames(classes.buttonMargins, classes.dateStyles)}>
                  <h3 className={classes.noBottomMargin}>R&D Engineering Intern/Co-Op</h3>
                  <h3>2015 - 2017</h3>
                </div>
                <div>
                  <h4 className={classNames(classes.blueLinks, classes.companyStyles)}>
                    <a href="https://en.wikipedia.org/wiki/Y-12_National_Security_Complex" target="_blank">U.S. Department of Energy</a>
                  </h4>
                </div>
                <ul className={classes.pStyles}>
                  <li>Utilized LabVIEW to develop applications that established full control over several critical systems.</li>
                  <li>Selected from a large pool of interns to receive an exclusive offer to continue my research as a Co-Op Student by exhibiting unparalleled work performance.</li>
                  <li>Developed a novel method to allow printing from embedded Linux hardware. This method was accepted by National Instruments and was used by their engineers as the solution to this problem.</li>
                  <li>Programmatically implemented a new gas calibration process for vacuum chambers using embedded hardware that cut system operating costs by over $315,000 per year.</li>
                </ul>
              </GridItem>
              <GridItem className={classes.zoodillyPadding}>
              <div className={classNames(classes.buttonMargins, classes.dateStyles)}>
                  <h3 className={classes.noBottomMargin}>Physics Teaching Assistant</h3>
                  <h3>2014 - 2016</h3>
                </div>
                <div>
                  <h4 className={classNames(classes.blueLinks, classes.companyStyles)}>
                    <a href="https://www.tntech.edu/cas/physics/" target="_blank">Tennessee Tech University</a>
                  </h4>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <h2>Education</h2>
              </GridItem>

              <GridItem className={classes.zoodillyPadding}>
              <div className={classNames(classes.buttonMargins, classes.dateStyles)}>
                  <h3 className={classes.noBottomMargin}>B.S., Computer Engineering</h3>
                  <h3>2013 - 2017</h3>
                </div>
                <div>
                  <h4 className={classNames(classes.blueLinks, classes.companyStyles)}>
                    <a href="https://www.tntech.edu/engineering/programs/ece/undergraduate/computer-engineering/index.php" target="_blank">Tennessee Tech University</a>
                  </h4>
                </div>
                
                <p className={classes.pStyles}>
                  <b className={classes.betterBold}>Activities and societies:</b> Sigma Chi Fraternity (Executive Committee), IEEE,
                  National Science Foundation iCORPS, Innovation & Entrepreneurship Program,
                  EagleWorks Startup Program, Denso Autonomous Robotics Team, Outdoor Club.
                </p>
                <p className={classes.pStyles}>
                  <b className={classes.betterBold}>Course Topics:</b> Data Structures & Algorithms, Cryptography, Operating Systems,
                  Computer Networks, Circuit & Electronic Design, Embedded Systems, Continuous & Discrete Signals and Systems,
                  Microcomputer Systems, Mathematical & Modern physics, Autonomous Robotics, Probability & Statistics, and more.
                </p>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
