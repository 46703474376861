import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/iconss

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import { Helmet } from "react-helmet";

import styles from "assets/jss/material-kit-react/views/projectsPage.js";

const useStyles = makeStyles(styles);
const TITLE = "Projects | Delong.ai 🚀";

export default function ProjectsPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Header
        color="transparent"
        brand="🚀 Delong.ai"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />


      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <h2>Projects</h2>
              </GridItem>
              <GridItem>
                <Button className={classes.buttonNoPadding}
                  href="http://jhd.group"
                  target="_blank"
                  color="transparent"
                >
                  <h3 className={classes.blueLinks}>Johnson, Hall, & Delong Strategies </h3><h3><muted>&nbsp; 2024-present</muted></h3>
                </Button>

                <p className={classes.pStyles}>
                  Consulting company launched with the purpose of building cost effective AI solutions catered to small businesses' needs.
                  At the time of writing (3 months after formation), we've already signed 2 clients who have brought us on to deploy a custom audio capture and AI analysis tool
                  throughout their sales teams that I've been building throughout the past six months. 
                </p>
              </GridItem>
              <GridItem>
                <Button className={classes.buttonNoPadding}
                  href="http://buffett.dev"
                  target="_blank"
                  color="transparent"
                >
                  <h3 className={classes.blueLinks}>Buffett AI, </h3><h3><muted>&nbsp; 2020-2022</muted></h3>
                </Button>

                <p className={classes.pStyles}>
                  An automated stock market bot that produces buy or short
                  predictions based on real-time insider trading disclosures
                  from the SEC. The bot first analyzes all historical data of
                  insider trades to create a model of which insider trades are
                  most likely to lead to higher-than-average returns. Then,
                  Buffett uses this model coupled with real-time insider trading
                  data to make informed decisions on which stocks to buy or
                  short—written primarily with Python.
                </p>
              </GridItem>
              <GridItem>
                <Button className={classes.buttonNoPadding}
                  color="transparent"
                >
                  <h3>Fetch AIO, </h3><h3>&nbsp; 2020-2021</h3>
                </Button>

                <p className={classes.pStyles}>
                  With the release of Nvidia's new GPUs, the PS5, and others, the conversation around automated bots with the ability to make purchases greatly heated up. Fetch AIO (All-In-One)
                  was my response to the trend. Fetch AIO had the ability to subtly query websites looking for whichever product(s) you're interested in that are also in stock. The moment the desired 
                  product becomes available, Fetch completes the checkout process and snags the product before it's gone. Written primarily with Python.
                </p>
              </GridItem>
              <GridItem>
                <Button className={classes.buttonNoPadding}
                  href="https://www.nuhni.com"
                  target="_blank"
                  color="transparent"
                >
                  <h3 className={classes.blueLinks}>Nuhni.com, </h3><h3>&nbsp; 2017-2022</h3>
                </Button>

                <p className={classes.pStyles}>
                  Nuhni is a business that provides sustainable consumer
                  products that outperform their synthetic counterparts. I
                  helped start Nuhni and was primarily responsible for leading
                  its software effort and building scalable tools that allowed
                  us to automate our workload, keeping our overhead small.
                  During my time with Nuhni, it grew from no revenue to a
                  valuation nearing $1,000,000 with no outside investments.
                  However, after adjusting to the new business world
                  post-COVID-19, Nuhni&apos;s needs changed, and I saw fit to sell my
                  equity and move on to new opportunities.
                </p>
              </GridItem>
              <GridItem>
                <div className={classNames(classes.buttonNoPadding, classes.buttonMargins)}
                  color="transparent"
                >
                  <h3>Event Wizard, <muted>2018-2019</muted></h3>
                </div>

                <p className={classes.pStyles}>
                  Event Wizard was an automated tool for any business that
                  frequented markets, tradeshows, or any other in-person event.
                  Because there is no unified system for these events, finding
                  new events or signing up for them was a very time-consuming
                  process. Event Wizard streamlined the process by discovering
                  new events within a given city and signing any business up for
                  the events if all the supplied criteria were met. Event Wizard
                  is no longer supported because it relied heavily on
                  Eventbrite, which stopped providing critical information
                  through its API—written primarily with Python.
                </p>
              </GridItem>
              <GridItem>
                <div className={classNames(classes.buttonNoPadding, classes.buttonMargins)}
                  color="transparent"
                >
                  <h3>nhu.io, <muted>2016-2017</muted></h3>
                </div>

                <p className={classes.pStyles}>
                  (Sold nhu.io domain in 2020) Nhu was an instant checkout
                  system for retail stores that used NFC technology to eliminate
                  lines, increase profit, and remotely manage inventory. We
                  developed a custom hardware/software suite that accomplished
                  our goal of instantly scanning all items within a shopping
                  cart simply by pushing the cart through our NFC towers. This
                  project made substantial progress towards it&apos;s goal, and
                  received outside investment in 2017. However, due to dwindling
                  funds and beginning my first software engineering job, the project
                  was shut down in mid-2017.
                  <br></br>
                  <br></br>
                  The project was accomplished using a variety of technologies.
                  For the customer/retail interface, we used a MEAN stack (Mongo
                  DB, Express JS, Angular, and Node JS.) The hardware was built
                  using an array of highly customized ESP32 microcontrollers
                  that each operated multiple NFC readers/writers fitted with
                  custom circuitry for the antenna.
                </p>
              </GridItem>
              <GridItem className={classes.zoodillyPadding}>
                <Button className={classes.buttonNoPadding}
                  href="http://www.zoodilly.com"
                  target="_blank"
                  color="transparent"
                >
                  <h3 className={classes.blueLinks}>Zoodilly.com, </h3><h3>&nbsp; 2016-2018</h3>
                </Button>

                <p className={classes.pStyles}>
                  A social media marketing company that set itself apart by using an automated bot to grow the social media accounts of its clients with real followers. In addition to the automated
                  bot that ran the accounts, we also used a propriety hardware/software system that subverted Facebook's advanced algorithm to detect bots. The project was shutdown in order to focus
                  on another business that was bringing in more revenue. Technologies used were: Raspberry Pi, proxy servers, javascript, shell scripting, and more.
                </p>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
