import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import { Helmet } from "react-helmet";

import profile from "assets/img/faces/drew.jpg";

// Sections for this page
import WorkSection from "../LandingPage/Sections/WorkSection.js";

import { Link } from "react-router-dom"

import styles from "assets/jss/material-kit-react/views/profilePage.js";


const useStyles = makeStyles(styles);
const TITLE = "About Me | Delong.ai 🚀";

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  return (
    <div>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Header
        color="transparent"
        brand="🚀 Delong.ai"
        rightLinks={<HeaderLinks />
        }
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />


      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Hi, I'm Drew</h3>
                    <h6>Software Engineer | Tech Dreamer</h6>
                  </div>
                </div>
                <div className={classes.description}>
                  <p>
                    I&apos;ve been passionate about technology, building things, and
                    especially the cross-section between them since I was a kid.
                    Over the years, I&apos;ve leveraged this passion for building
                    some projects that are still active today. See my{" "}
                    <Link to="/projects">projects page</Link> to learn more.
                    This fascination carried me through my education, where I
                    graduated in 2017 with a bachelor&apos;s in Computer Engineering.
                  </p>
                  <p>
                    After graduation, I was given the opportunity to continue my
                    intern experience as a full-time Software Engineer in Research and Development for the
                    U.S. Department of Energy at the Y-12 National Security
                    Complex. Among other things, I led a project&apos;s software
                    effort to implement a full-stack solution utilizing machine
                    learning to predict machine failure preemptively. In
                    addition, I received a performance award for upgrading
                    software on a legacy system that doubled the system&apos;s
                    throughput.
                  </p>
                  <p>
                    Even upon leaving the office, I tend to continue writing
                    code. In 2019, this habit provided me the privilege of
                    beginning to work full-time on a business, Nuhni, that I had
                    helped start. I spearheaded my position as Co-Founder by
                    expanding Nuhni&apos;s digital strategies and building scalable,
                    automated systems. In just a few years, Nuhni experienced
                    rapid growth into a profitable business with nearly a dozen
                    employees and a valuation of over $1,000,000. In late 2020, I was able to successfully exit 
                    the company; selling the equity position I had in the venture.
                  </p>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className={classNames(classes.main)}>
          <div className={classes.container}>
            <WorkSection />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
