import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, Input, FormHelperText } from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";

// icons
import CircularProgress from '@material-ui/core/CircularProgress';
import EmailIcon from '@material-ui/icons/Email';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles)

const REACT_APP_SERVICE = "service_mssoxhk";
const REACT_APP_USER = "user_I7IUuB30pXyw5t0R7OAV2";
const REACT_APP_TEMPLATE = "template_8j2tzt4";

export default function WorkSection() {
  const classes = useStyles();
  const { register, errors, handleSubmit, reset } = useForm();
  const [loading, setLoading] = React.useState(false);


  const toastifySuccess = () => {
    toast.success('🚀 Message sent, thanks!', {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const templateParams = {
        name: data.name,
        email: data.email,
        message: data.content,
      };
      await emailjs.send(
        REACT_APP_SERVICE,
        REACT_APP_TEMPLATE,
        templateParams,
        REACT_APP_USER
      );
      reset();
      toastifySuccess();
      setLoading(false)
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Contact Me</h2>
          <h4 className={classes.description}>
            Have or question, or just wanna chat? I'd love to hear from you!
          </h4>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.formMargin} noValidate>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} className={classes.inputSpacing}>
                <FormControl fullWidth className={classes.formControl}>
                  {!errors.name && <InputLabel htmlFor="name">Your Name</InputLabel>}
                  {errors.name && <InputLabel error htmlFor="name">Your Name</InputLabel>}
                  <Input
                    inputRef={register({
                      required: {
                        value: true,
                        message: "Please enter your name",
                      },
                      maxLength: {
                        value: 30,
                        message: "Please use 30 characters or less",
                      },
                    })}
                    fullWidth
                    id="name"
                    label="Your Name"
                    name="name"
                    error={errors.name ? true : false}
                  />
                  {errors.name && (<FormHelperText id="name-error" error>{errors.name.message}.</FormHelperText>)}
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className={classes.inputSpacing}>
                <FormControl fullWidth className={classes.formControl}>
                  {!errors.email && <InputLabel htmlFor="email">Your Email</InputLabel>}
                  {errors.email && <InputLabel error htmlFor="email">Your Email</InputLabel>}
                  <Input
                    id="email"
                    aria-describedby="my-helper-text"
                    inputRef={register({
                      required: true,
                      pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    })}
                    fullWidth
                    name="email"
                    error={errors.email ? true : false}
                    aria-describedby="email-error"
                  />
                  {errors.email && (<FormHelperText id="email-error" error>Please enter a valid email address{ }</FormHelperText>)}

                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={classes.inputSpacing}>
                <FormControl fullWidth className={classes.formControl}>
                  {!errors.content && <InputLabel htmlFor="content">Your Message</InputLabel>}
                  {errors.content && <InputLabel htmlFor="content" error>Your Message</InputLabel>}
                  <Input
                    inputRef={register({
                      required: true,
                    })}
                    fullWidth
                    id="content"
                    label="Your Message"
                    name="content"
                    multiline
                    rows={5}
                    error={errors.content ? true : false}
                    aria-describedby="msg-error"
                  />
                  {errors.content && (<FormHelperText id="msg-error" error>Please enter a message to send.</FormHelperText>)}
                </FormControl>
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <Button color="danger" type="submit" disabled={loading}>
                  <EmailIcon></EmailIcon>
                  Send Message
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </GridItem>
              <ToastContainer
                position="bottom-center"
                autoClose={100000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
